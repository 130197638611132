const CompanyManagementIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.10018 1L12.1912 10.2679L5.59949 10.2493L6.542 8.71986L8.80994 8.73842L5.9169 3.19126L7.10018 1ZM13.5039 13H0.503906L5.45385 4.06508L6.70544 6.46297L3.97192 11.1162H12.4671L13.5039 13Z"
          fill="black" fillOpacity="0.85"/>
  </svg>

)

export default CompanyManagementIcon;
