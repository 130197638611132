import { FC, memo } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import useGlobals from '@hooks/useGlobals';

import type { TRouter } from '@local-types/global';

import disclaimerData from '@data/disclaimer';

import styles from './Disclaimer.module.scss';

const Disclaimer: FC<{}> = () => {
  const { isDarkTheme } = useGlobals()[1];
  const router = useRouter();
  const { locale } = router as TRouter;
  const [text1, link, text2] = disclaimerData[locale];

  return (
    <div
      className={cn(styles.Disclaimer, {
        [styles.darkTheme]: isDarkTheme,
      })}
    >
      {/* @ts-ignore */}
      <span>
        {typeof text1 === "string" ? text1 : null}
        {/* @ts-ignore */}
        <a href={link.link} target="_blank">
          {/* @ts-ignore */}
          {link.text}
        </a>
        {text2}
      </span>
    </div>
  );
};

export default memo(Disclaimer);
