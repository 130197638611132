import React, { FC, Fragment, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import Link from '@components/NextLink';
import useGlobals from '@hooks/useGlobals';
import type { TNavbarDataItem } from '@local-types/data';
import SubMenuItem from '@components/Navbar/SubMenuItem';

import CompanyManagementIcon from '@icons/CompanyManagementIcon';
import UXCoreIcon from '@icons/UXCoreIcon';

import styles from './MenuItem.module.scss';

type TMenuItem = {
  locale: string;
  data: TNavbarDataItem;
  activeUrl: string;
  isCompanyManagementPage?: boolean;
};

const MenuItem: FC<TMenuItem> = ({
  locale,
  data,
  activeUrl,
  isCompanyManagementPage,
}: TMenuItem) => {
  const { asPath } = useRouter();
  const [{ toggleSidebar }, { isDarkTheme, isOpenedSidebar }] = useGlobals();
  const [blockHover, setBlockHover] = useState(false);

  const handleClickSubMenu = useCallback(() => {
    setBlockHover(prevState => !prevState);

    setTimeout(() => {
      setBlockHover(prevState => !prevState);
    }, 50);
  }, []);

  const handleMenuLinkClick = useCallback(() => {
    if (isOpenedSidebar) toggleSidebar();
  }, []);

  const isIconDefined = !!data.icon;
  const uxCoreLink = data.href === '/uxcore';
  const uxCoreIconAndLink = isIconDefined && uxCoreLink;

  const companyManagementLink = data.href === '/company-management';

  const subMenuHref = data?.tools?.map(el =>
    el?.submenuItems.find(el => el.href === asPath),
  );
  const productManagementSubTitle = subMenuHref?.[0]?.href === asPath;
  const projectManagementSubTitle = subMenuHref?.[1]?.href === asPath;

  const handleLinkClick = useCallback(() => {
    handleClickSubMenu && handleClickSubMenu();
    if (isOpenedSidebar) toggleSidebar();
  }, [toggleSidebar, isOpenedSidebar, handleClickSubMenu]);

  return (
    <div
      className={cn(styles.menuItem, {
        [styles.blockHover]: blockHover,
        [styles.ruVersion]: locale === 'ru',
        [styles.active]:
          activeUrl === data.href ||
          productManagementSubTitle ||
          projectManagementSubTitle,
        [styles.darkTheme]: isDarkTheme,
        [styles.companyManagementPage]: isCompanyManagementPage,
        [styles.companyManagementPageEng]:
          isCompanyManagementPage && locale === 'en',
        [styles.companyManagementPageRu]:
          isCompanyManagementPage && locale === 'ru',
      })}
    >
      {data.href ? (
        <Link href={data.href} locale={locale} shallow={false}>
          <a target={data.target} onClick={handleLinkClick}>
            {uxCoreIconAndLink && <UXCoreIcon />}
            {companyManagementLink && <CompanyManagementIcon />}
            {data.title}
          </a>
        </Link>
      ) : (
        <div className={cn(styles.managementTools, {})}>
          <span
            className={cn(styles.title, {
              [styles.ruTitle]: locale === 'ru',
            })}
          >
            {data.title}
          </span>
          <div className={styles.wrapper}>
            <div
              className={cn(styles.dropDown, {
                [styles.closedDropDown]: blockHover,
              })}
            >
              {data.tools?.map((el, index) => {
                const titleIndex = index;
                return el?.href ? (
                  <Fragment key={el.title}>
                    <Link href={el?.href} locale={locale} shallow={false}>
                      <span
                        className={cn(styles.subTitle, {
                          [styles.letterSpacing]: locale === 'ru',
                        })}
                        data-id={index}
                        onClick={handleClickSubMenu}
                      >
                        {el.title}
                      </span>
                    </Link>
                  </Fragment>
                ) : (
                  <div className={styles.content} key={el.title}>
                    <span
                      onClick={handleMenuLinkClick}
                      data-id={index}
                      className={cn(styles.subTitle, {
                        [styles.letterSpacing]: locale === 'ru',
                      })}
                    >
                      {el.title}
                    </span>
                    {data?.tools?.map((el, index) => (
                      <Fragment key={index}>
                        {index === titleIndex && (
                          <Fragment>
                            {el?.submenuItems?.length > 0 && (
                              <ul className={styles.subMenu}>
                                {el?.submenuItems.map(
                                  ({ label, ...restData }, index: number) =>
                                    !!label ? (
                                      <li
                                        key={index}
                                        className={styles.subMenuTitle}
                                      >
                                        {label}
                                      </li>
                                    ) : (
                                      <SubMenuItem
                                        locale={locale}
                                        key={index}
                                        data={restData}
                                        onClick={handleClickSubMenu}
                                        isActive={restData.href === asPath}
                                        isDarkTheme={isDarkTheme}
                                        isCompanyManagementPage={
                                          isCompanyManagementPage
                                        }
                                      />
                                    ),
                                )}
                              </ul>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;
