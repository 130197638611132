import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import Link from '@components/NextLink';
import styles from './SubMenuItem.module.scss';
import type { TNavbarSubmenuItems } from '@local-types/data';
import useGlobals from '@hooks/useGlobals';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import useContentType from '@hooks/useContentType';

type TSubMenuItem = {
  locale: string;
  data: TNavbarSubmenuItems;
  isActive: boolean;
  onClick: () => void;
  isDarkTheme?: boolean;
  isCompanyManagementPage?: boolean;
};

const SubMenuItem: FC<TSubMenuItem> = ({
  locale,
  data,
  isActive,
  onClick,
  isDarkTheme,
                                         isCompanyManagementPage,
}: TSubMenuItem) => {
  const [{ toggleSidebar }, { isOpenedSidebar }] = useGlobals();
  const { prefix, title, href, target, answers, icon } = data;
  const handleLinkClick = useCallback(() => {
    onClick && onClick();
    if (isOpenedSidebar) toggleSidebar();
  }, [toggleSidebar, isOpenedSidebar, onClick]);
  const { componentList } = useContentType(styles, true);

  const isExternalLink = href.indexOf('http') === 0;

  const content = useMemo(
    () => (
      <>
        <span>
          {prefix} {title}{' '}
        </span>
        {answers && (
          <div className={styles.containsAnswers}>
            <ReactMarkdown
              children={answers.description}
              className={styles.content}
              components={componentList}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
        )}
        {!!icon &&
          (isDarkTheme ? (
            <img
              className={styles.Icon}
              alt={`${icon} icon`}
              src={`/assets/icons/${icon}-dark.svg`}
            />
          ) : (
            <img
              className={styles.Icon}
              alt={`${icon} icon`}
              src={`/assets/icons/${icon}.svg`}
            />
          ))}
      </>
    ),

    [prefix, title, answers, icon, isDarkTheme],
  );

  return (
    <li
      className={cn(styles.subMenuItem, {
        [styles.active]: isActive,
        [styles.darkTheme]: isDarkTheme,
        [styles.ruVersion]: locale === 'ru',
        [styles.companyManagementPage]: isCompanyManagementPage,
      })}
    >
      {!isExternalLink ? (
        <Link href={href} locale={locale} shallow={false}>
          <a
            target={target}
            onClick={handleLinkClick}
            rel={'noopener noreferrer'}
          >
            {content}
          </a>
        </Link>
      ) : (
        <a href={href} target={target}>
          {content}
        </a>
      )}
    </li>
  );
};

export default SubMenuItem;
