import React, { FC, useContext } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import type { TRouter } from '@local-types/global';
import useGlobals from '@hooks/useGlobals';
import MenuItem from './MenuItem';
import type { TNavbarDataItem } from '@local-types/data';
import { GlobalContext } from '@components/Context/GlobalContext';

import styles from './Navbar.module.scss';

type NavbarProps = {
  articles: TNavbarDataItem[];
  activeUrl: string;
  isCompanyManagementPage?: boolean;
};

const Navbar: FC<NavbarProps> = ({ articles, activeUrl, isCompanyManagementPage }) => {

  const router = useRouter();
  const { isDarkTheme, isOpenedSidebar } = useGlobals()[1];
  const { accountData } = useContext(GlobalContext);
  const { locale } = router as TRouter;
  
  return (
    <aside
      className={cn(styles.aside, {
        [styles.darkTheme]: isDarkTheme,
        [styles.openedSidebar]: isOpenedSidebar,
        [styles.companyManagement]: isCompanyManagementPage,
      })}
    >
      <div
        className={cn(styles.menu, {
          [styles.authorized]: !!accountData,
        })}
      >
        {articles.map((menu: TNavbarDataItem, index: number) => {
          return (
            <MenuItem
              key={index}
              locale={locale}
              data={menu}
              activeUrl={activeUrl}
              isCompanyManagementPage={isCompanyManagementPage}
            />
          );
        })}
      </div>
    </aside>
  );
};

export default Navbar;
