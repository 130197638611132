const ru = [
  `Данный фреймворк был разработан для компании `,
  {
    link: 'https://sdc.am',
    text: 'The Software Development Company',
  },
  ` в 2021м году.`,
];

export default ru;
