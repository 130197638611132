const en = [
  `This framework was built for `,
  {
    link: 'https://sdc.am',
    text: 'The Software Development Company',
  },
  ` in 2021.`,
];

export default en;
